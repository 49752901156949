<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" class="v-spinner">
            <circle cx="25" cy="25" r="20" fill="none" stroke="url(#sback)" stroke-width="5"></circle>
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke="url(#sfront)" stroke-width="5"></circle>
            <defs>
                <linearGradient id="sfront">
                    <stop offset=".001" stop-color="#7449E6" />
                    <stop offset=".771" stop-color="#69A4FF" />
                    <stop offset="1.001" stop-color="#69a4ff" stop-opacity=".9" />
                </linearGradient>
                <linearGradient id="sback">
                    <stop offset=".001" stop-color="#7449e6" stop-opacity=".2" />
                    <stop offset=".771" stop-color="#69a4ff" stop-opacity=".2" />
                    <stop offset="1.001" stop-color="#69a4ff" stop-opacity=".18" />
                </linearGradient>
            </defs>
        </svg>
    </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.v-spinner {
    animation: rotate 2s linear infinite;
    margin: 0 auto;
    display: flex;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;

    & .path {
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }

        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }

        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
}
</style>
